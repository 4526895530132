import service from '@/utils/service.js';

export function userLogin(data){
    return service.post('/user/login',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getUserInfo(params){
    return service.get('/user/base/base-info',{
        params,
    })
}

export function userLogout(params){
    return service.get('/user/logout',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
}

export function userConfig(params){
    return service.get('/user/config',{
        params,
    })
}

export function getContrastList(data) {
	return service.get(`/user/base/settings/${data.name}`)
}

export function setContrastList(data) {
	const { name, contrastUserIds } = data
	return service.post(`/user/base/settings/${name}`, { contrastUserIds })
}