<template>
    <div class="login">
        <div class="form-box">
            <h2 class="title"> <img src="@/assets/title.png" alt="" class="title-img"> <span class="span">河北法院干部政治素质和业绩档案管理系统</span></h2>
            <el-form ref="form" :model="form" :rules="rules" class="form">
                <el-form-item prop="username" label="用户名" >
                    <el-input prefix-icon="el-icon-user-solid" placeholder="请输入用户名" v-model="form.username" @keydown.native="keydown($event)">
                    </el-input>
                </el-form-item>
                <el-form-item prop="password" label="用户密码" >
                    <el-input :type="open ? 'input' : 'password'" placeholder="请输入密码"
                        prefix-icon="el-icon-lock" suffix-icon=“” v-model="form.password" @keydown.native="keydown" >
                        <i class="eye" slot="suffix" :class="pswSeen"  @click="open = !open"></i>
                    </el-input>
                        <!-- <button type="button" slot="suffix" @click="open = !open"><i :class="pswSeen"></i></button> -->
                </el-form-item>
                <!-- <el-form-item>
                    <el-checkbox :value="isRemember" @change="toggleSave" :style="'color:#3875CE'">记住密码</el-checkbox>
                </el-form-item> -->
                <el-form-item>
                    <el-button class="btn-login" @click="handleLogin">登录</el-button>
                </el-form-item>
                <el-form-item>
                    <div class="text" :style="'color: #3875CE;'">没有账号？ 请联系管理员</div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { userLogin } from '@/api/loginAPI.js';
import store from '@/store'
export default {
    name: "login",
    data () {
        return {
            form: {
                username: "",
                password: "",
                token:'1'
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
            },
            //   isRemember: this.$store.state.user.isRemember,
            loading: false,
            redirect: null,
            open: false,
            passwordType: "password",
        };
    },
    created () {
        // this.form = this.account;
    },
    computed: {
        // ...mapGetters(["isRemember"]),
        pswSeen () {
            return ["iconfont", this.open ? "icon-yanjing" : "icon-yanjing_bi"];
        },
        account () {
            return this.$store.getters.account;
        }
    },
    methods: {

        // ...mapActions("user", ["login"]),
        keydown (event) {
            if (event.keyCode === 13) {
                this.handleLogin();
            };
            if(event.keyCode === 32){
                event.returnValue = false
            };
        },
        async handleLogin () {   
            this.$refs.form.validate(async (valid) => {
                if(valid){
                    let res= await userLogin(this.form)
                    if(res.token_type && res.access_token){
                        const token = `${res.token_type} ${res.access_token}`;
                        store.commit('common/SET_TOKEN',token);
                        this.$router.push({ name:'entrance' });
                    }
                }
            })
            
        },
        toggleSave () {
            this.$store.commit("user/TOGGLE");
        },
    },
};
</script>

<style lang="less" scoped>
.login {
    display: flex;
    // justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("~@/assets/login.jpg");
    background-size: 100% 100%;

    .form-box {
        margin-left: 196px;
        // background-image: url("~@/assets/login.jpg");
        width: 800px;
        height: 527px;

        .title {
            display: flex;
            height: 51px;
            margin: 65px 0;
            padding-left: 15px;
            text-align: center;
            color: #3875CE;
            .title-img{
                height: 51px;
                width: 43px;
                margin-right: 16px;
            }
            .span{
                display: inline-block;
                height: 51px;
                line-height: 51px;
                font-size: 24px;
            }
        }

        .form {
            width: 500px;
            margin-left: 30px;
            // margin: 0 ;

            .btn-login {
                margin-left: 50px;
                width: 100%;
                color: #fff;
                background: #3875CE;
                border: none;
            }
            .text{
                width: 100%;
                height: 100%;
                color: #3875CE ;
                display: block;
                text-align: center;
            }
            .eye{
                cursor: pointer;
                    color: #333;
            }
        }
    }
    .el-form.form /deep/ .el-form-item{
        display: flex;
        justify-content: space-between;

        .el-form-item__label{
            width: 100px;
            text-align: left;
        }
        .el-form-item__content{
            width: 400px;
        }
    }
    /deep/ .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: #08ebfe;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background: #07adc8;
        border-color: #409eff;
    }

    /deep/ .el-checkbox {
        color: #fff;
    }

    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #fff;
    }

    /deep/ .el-icon-user:before {
        font-size: 16px;
    }

    /deep/ .el-icon-lock:before {
        font-size: 16px;
    }
}
</style>

